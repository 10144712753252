<template>
    <div class="pa-5">
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols="12" sm="7">
                        <v-btn color="primary" elevation="0" @click="$store.state.loading=true;showAllLeaves()" :disabled="$store.state.loading">
                            <v-icon>mdi-table</v-icon>  Employee Leaves
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="5">
                        <v-row>
                            <v-col>
                                <v-select dense 
                                    label="Month"
                                    v-model="month" 
                                    :items="months" 
                                    item-value="value" 
                                    item-text="text">
                                </v-select>
                            </v-col>
                            <v-col>
                                <v-select dense 
                                    label="Year"
                                    v-model="year" 
                                    :items="years" 
                                    item-value="value" 
                                    item-text="text">
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-simple-table dense class="bordered-table">
                <template v-slot:default>
                    <thead class="grey lighten-2 white--text">
                        <tr>
                            <th class="text-left" style="min-width: 200px;">
                                Name
                            </th>
                            <th class="text-left" v-for="(d, idx) in days" :key="idx">
                                {{ d.day }}
                                <span style="font-size:10px;">{{ d.week_day }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(l, idx) in staff_leaves" :key="idx">
                            <td>
                                <span style="font-size: 11px;">
                                    {{ l.staff.name }}
                                </span>
                            </td>
                            <td v-for="d in l.days" :key="d.date" :class="d.leave ? getLeaveClass(d.leave) : ''" @click="showLeaveDetails(l.staff.name, d)">
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>

        <v-dialog width="400" v-model="show_leave_details">
            <v-card>
                <v-card-title>
                    Leave Details | {{ leave.name }} 
                    &nbsp;
                    <v-chip class="orange lighten-2" small v-if="leave.details?.approved == 0 && leave.details?.declined == 0">Pending</v-chip>
                    <v-chip class="green lighten-2" small v-if="leave.details?.approved == 1">Approved</v-chip>
                    <v-chip class="red lighten-2" small  v-if="leave.details?.declined == 1">Declined</v-chip>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-5">
                    <v-text-field dense label="Date" 
                        disabled 
                        outlined
                        v-model="leave.leave_date"></v-text-field>
                    <v-text-field dense label="Leave Type" 
                        disabled 
                        outlined
                        v-model="leave.details.leave_type"></v-text-field>
                    <v-textarea dense label="Reason"
                        disabled 
                        outlined
                        rows="2"
                        v-model="leave.details.leave_reason"></v-textarea>
                    <v-textarea dense label="Approver Remarks" 
                        :disabled="leave.details.approved == 1 || leave.details.declined == 1"
                        rows="2"
                        outlined
                        v-model="leave.details.approver_remarks"
                        hide-details></v-textarea>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text v-if="leave.details?.approved == 0 && leave.details?.declined == 0">
                    <div class="pt-5">
                        <v-row>
                            <v-col>
                                <v-btn color="primary" small block 
                                    :disabled="!leave.details.approver_remarks"
                                    @click="approveLeaveRequest(leave.details)">Approve</v-btn>
                            </v-col>
                            <v-col>
                                <v-btn color="error" small block 
                                    :disabled="!leave.details.approver_remarks"
                                    @click="declineLeaveRequest(leave.details)">Decline</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog max-width="1200" v-model="show_all_leaves">
            <v-card>
                <v-card-title>
                    Employee Leaves
                </v-card-title>
                <v-card-title>
                    <v-row>
                        <v-col>
                            <v-select dense outlined hide-details label="Staff"
                                item-value="id"
                                item-text="name"
                                color="primary"
                                :items="filter_items.users"
                                v-model="filter_values.staff"
                                >
                            </v-select>
                        </v-col>
                        <v-col>
                            <v-select dense outlined hide-details label="Status"
                                item-value="id"
                                item-text="text"
                                color="primary"
                                :items="filter_items.status"
                                v-model="filter_values.status"
                                >
                            </v-select>
                        </v-col>
                        <v-col>
                            <v-select dense outlined hide-details label="Month"
                                item-value="value"
                                item-text="text"
                                color="primary"
                                :items="filter_items.months"
                                v-model="filter_values.month">
                            </v-select>
                        </v-col>
                        <v-col>
                            <v-select dense outlined hide-details label="Year"
                                item-value="value"
                                item-text="text"
                                color="primary"
                                :items="filter_items.years"
                                v-model="filter_values.year"
                                >
                            </v-select>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn small color="default" elevation="0" block @click="filterResults()">Filter</v-btn>
                </v-card-actions>
                <v-divider></v-divider>
                <v-simple-table>
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Name
                            </th>
                            <th class="text-left">
                                Role
                            </th>
                            <th class="text-left">
                                Leave Date
                            </th>
                            <th class="text-left">
                                Leave Type
                            </th>
                            <th class="text-left">
                                Status
                            </th>
                            <th class="text-left">
                                Remarks
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="leave in all_leaves"
                            :key="leave.id"
                            >
                            <td>{{ leave.name }}</td>
                            <td>{{ leave.account_type }}</td>
                            <td>{{ leave.leave_date_formatted }}</td>
                            <td>{{ leave.leave_type }}</td>
                            <td>
                                <span v-if="leave.approved == 0 && leave.declined == 0">Waiting for Approval</span>
                                <span v-if="leave.approved == 1">Approved</span>
                                <span v-if="leave.declined == 1">Declined</span>
                            </td>
                            <td>
                                {{ leave.approver_remarks }}
                            </td>
                        </tr>
                        <tr v-if="all_leaves.length == 0">
                            <td colspan="6">No Records Found.</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="6" class="text-right bold">
                                Number of Results : {{ all_leaves.length || 0 }}
                            </td>
                        </tr>
                    </tfoot>
                    </template>
                </v-simple-table>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
export default {
    name : 'StaffLeaves',
    data() {
        return {
            month : {},
            year : {},
            status_items : [],
            months : [],
            years : [],
            show_leave_details : false,
            staff_leaves : [],
            leave : {
                name : '',
                details : {}
            },
            days : [],
            all_leaves : [],
            show_all_leaves : false,
            filter_values : {},
            filter_items : {}
        }
    },
    watch : {
        month(newVal, oldVal) {
            this.refreshLeaves();
        },
        year(newVal, oldVal) {
            this.refreshLeaves();
        }
    },
    mounted() {
        this.month = moment().format('MM');
        this.year = moment().format('YYYY');
        this.years = [];
        for(let y = this.year; y >= this.year - 5; y--) {
            this.years.push({
                value : y,
                text : y
            });
        }

        this.months = [];
        for(let m = 1; m <= 12; m++) {
            let month =  m.toString().padStart(2, '0');
            let date = this.year + '-' + month + '-01';
            this.months.push({
                value : moment(date).format('MM'),
                text : moment(date).format('MMMM'),
            })
        }
        
        this.refreshLeaves();
        this.refreshUsers();


        this.filter_values = {
            year : this.year,
            month : null,
            staff : null,
            status : 'approved'
        };
    },
    methods: {
        refreshUsers() {
            axios({
                url : process.env.VUE_APP_API_URL + '/users',
                method : 'GET',
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                let years = Object.assign([], this.years);
                years.unshift({value : null, text : 'All'})

                let months = Object.assign([], this.months);
                months.unshift({value : null, text : 'All'});

                let users = res.data;
                users.unshift({id : null, name : 'All'});

                this.filter_items = {
                    years : years,
                    months : months,
                    users : users,
                    status : [
                        {id : '', text : 'All'},
                        {id : 'pending', text : 'Waiting for Approval'},
                        {id : 'approved', text : 'Approved'},
                        {id : 'declined', text : 'Declined'},
                    ]
                };
            }).catch(err => {
                console.log(err);
            });
        },
        refreshLeaves() {
            axios({
                url : process.env.VUE_APP_API_URL + '/staff-leaves',
                method : 'GET',
                params : {
                    month : this.month,
                    year : this.year
                },
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                let output = res.data;
                this.staff_leaves = output.data.summary;

                this.days = [];
                let days = output.data.days;
                for(let i = 1; i <= days; i++) {
                    this.days.push({
                        day : i,
                        week_day : moment(this.year + '-' + this.month + '-' + i.toString().padStart(2, '0')).format('ddd')
                    })
                }
            }).catch(err => {
                alert(JSON.stringify(err));
            });
        },
        getLeaveClass(leave) {
            if(leave.approved == 0 && leave.declined == 0) {
                return 'orange';
            }
            else if(leave.approved == 1) {
                return 'green';
            }
            else if(leave.declined == 1) {
                return 'red';
            }
        },
        showLeaveDetails(name, d) {
            if(!d.leave) {
                return;
            }
            this.leave = {
                name : name,
                leave_date : moment(d.date).format('MMM-DD-YYYY (dddd)'),
                details : d.leave,
            };
            console.log(this.leave);
            this.show_leave_details = true;
        },
        approveLeaveRequest(leave) {
            this.$store.state.loading = true;
            leave = Object.assign({}, leave);
            leave.approved = 1;
            leave.declined = 0;
            axios({
                url : process.env.VUE_APP_API_URL + '/staff-leaves/' + leave.id,
                method : 'PUT',
                data : leave,
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                let output = res.data;
                this.$store.state.loading = false;

                this.refreshLeaves();
                this.show_leave_details = false;
            }).catch(err => {
                this.$store.state.loading = false;
            });
        },
        declineLeaveRequest(leave) {
            this.$store.state.loading = true;

            leave = Object.assign({}, leave);
            leave.approved = 0;
            leave.declined = 1;
            axios({
                url : process.env.VUE_APP_API_URL + '/staff-leaves/' + leave.id,
                method : 'PUT',
                data : leave,
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                let output = res.data;
                this.$store.state.loading = false;

                this.refreshLeaves();
                this.show_leave_details = false;
            }).catch(err => {
                this.$store.state.loading = false;
            });

        },
        showAllLeaves() {
            axios({
                url : process.env.VUE_APP_API_URL + '/leaves/all',
                method : 'GET',
                params : this.filter_values,
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.all_leaves = res.data.data;
                this.show_all_leaves = true;
                this.$store.state.loading = false;
            }).catch(err => {
                console.log(err);
                this.$store.state.loading = false;
            })
        },
        filterResults() {
            this.showAllLeaves();
        }
    }
}
</script>
<style scoped>
    .bordered-table td {
        border: 1px solid rgba(0, 0, 0, 0.12); 
    }
</style>