import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-5"},[_c(VCard,[_c(VCardText,{staticClass:"grey lighten-3"},[_c('strong',[_vm._v("NOTIFICATIONS")])]),_c(VDivider),_c(VList,{attrs:{"dense":""}},_vm._l((_vm.notifications),function(n){return _c(VListItem,{key:n.id,attrs:{"link":""},on:{"click":function($event){return _vm.viewNotification(n)}}},[_c(VListItemContent,[_c(VListItemTitle,[_c(VBadge,{attrs:{"color":"pink","dot":"","offset-x":"-10","offset-y":"10","value":n.has_read == 0}},[_vm._v(" "+_vm._s(n.details)+" by "+_vm._s(n.sender?.name)+" ("+_vm._s(n.sender?.email)+") ")])],1),_c(VListItemSubtitle,[_vm._v(_vm._s(n.timestamp))])],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }