<template>
    <div>
        <v-row class="secondary" fixed no-gutters>
            <v-col class="pa-2">
                <v-btn small @click="openEmailLog()" v-if="job.job_status == 'LOG'">email log</v-btn>
            </v-col>
            <v-col class="text-right pa-2">
                <v-btn color="primary" small @click="show_status = true">{{ job.job_status }} </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="9" class="pl-5">
                <v-row v-if="job.staff">
                    <v-col>
                        <v-card>
                            <v-card-text class="grey lighten-3 pa-2">
                                <strong>Client Details</strong>
                            </v-card-text>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-icon
                                            class="grey lighten-1"
                                            dark
                                        >
                                            mdi-account
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="job.client_name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="job.client.email"></v-list-item-subtitle>
                                        <v-list-item-subtitle v-text="'Contact No:' + job.client_ref_no"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card>
                            <v-card-text class="grey lighten-3 pa-2">
                                <v-row no-gutters>
                                    <v-col>
                                        <strong>Staff Details</strong>
                                    </v-col>
                                    <v-col>
                                        <div class="pa-0 text-right" v-if="user.account_type == 'admin'">
                                            <v-btn color="mr-3" x-small v-if="['allocated', 'completed'].indexOf(job.job_status) == -1"
                                                @click="reAssignJob()"
                                                :disabled="$store.state.loading"
                                                >
                                                <v-icon>{{ $store.state.loading ? 'mdi-loading mdi-spin' : 'mdi-account-edit' }}</v-icon> re-assign
                                            </v-btn>
                                            <v-btn color="mr-3" x-small v-if="job.job_status == 'allocated'"
                                                @click="assignJob()"
                                                :disabled="$store.state.loading"
                                                >
                                                <v-icon>{{ $store.state.loading ? 'mdi-loading mdi-spin' : 'mdi-account-tie' }}</v-icon> Assign
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-icon
                                            class="grey lighten-1"
                                            dark
                                        >
                                            mdi-account-check
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="job.staff.name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="job.staff.email"></v-list-item-subtitle>
                                        <v-list-item-subtitle v-text="'Contact No: N/A'"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
                <v-card class="mt-5">
                    <v-card-text class="grey lighten-3 pa-2">
                        <v-row no-gutters>
                            <v-col :style="{'display': 'flex', 'align-items' : 'center'}">
                                <strong> JOB DETAILS</strong>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>Submission Date</th>
                                <th>Job Type & No.</th>
                                <th>Job No</th>
                                <th>Job Address</th>
                                <th>NCC</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ job.date_submitted }}</td>
                                <td>{{ job.job_type }}</td>
                                <td>{{ job.job_number }}</td>
                                <td>{{ job.job_address }}</td>
                                <td>{{ job.version }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
                <v-card class="mt-5">
                    <v-card-text class="grey lighten-3 pa-2">
                        <v-row>
                            <v-col><strong>NOTES</strong></v-col>
                            <v-col class="text-right">
                                <v-btn x-small @click="addNote()" color="primary">Add Note</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>Select</th>
                                <th>Notes</th>
                                <th>Report Link</th>
                                <th>Run No.</th>
                                <th>Climate Zone</th>
                                <th>Star Rating</th>
                                <th>Heating Load</th>
                                <th>Cooling Load</th>
                                <th :style="{width: '150px'}">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(j, idx) in job.job_notes" :key="j.id">
                                <td>
                                    <v-btn small icon :color="job.note_id == j.id ? 'primary' : 'grey'" @click="selectNote(j)">
                                        <v-icon>mdi-check-circle</v-icon>
                                    </v-btn>
                                </td>
                                <td>{{ j.notes }}</td>
                                <td class="text-center">
                                    <v-btn :title="j.report_link" target="__blank" :href="j.report_link" small icon>
                                        <v-icon>mdi-open-in-new</v-icon>
                                    </v-btn>
                                </td>
                                <td>{{ j.run_number }}</td>
                                <td>{{ j.climate_zone }}</td>
                                <td>{{ j.star_rating }}</td>
                                <td>{{ j.heating_load }}</td>
                                <td>{{ j.cooling_load }}</td>
                                <td>
                                    <v-btn x-small color="warning" @click="editNote(j)">edit</v-btn> &nbsp;
                                    <v-btn x-small color="error" @click="deleteNote(idx, j)">delete</v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
                <v-row class="mt-2">
                    <v-col>
                        <v-card>
                            <v-card-text class="grey lighten-3 pa-2">
                                <strong>ZIP File</strong>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-simple-table>
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-if="job.zips.length == 0">
                                            <td colspan="2">No records found</td>
                                        </tr>
                                        <tr
                                            v-for="p, idx in job.zips"
                                            :key="p.id"
                                        >
                                            <td class="text-blue pointer" @click="openLink('zips', p.job_id, p.type, p.filename)">
                                                {{ p.filename }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card>
                            <v-card-text class="grey lighten-3 pa-2">
                                <strong>PLANS</strong>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-simple-table>
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-if="job.plans.length == 0">
                                            <td colspan="2">No records found</td>
                                        </tr>
                                        <tr
                                            v-for="d, idx in job.plans"
                                            :key="d.id"
                                        >
                                            <td class="text-blue pointer" @click="openLink('plans', d.job_id, d.type, d.filename)">
                                                {{ d.filename }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="3">
                <div>
                    <div class="mb-5">
                        <v-btn x-small 
                            @click="active_section = 'conversation'" class="mr-2"
                            :color="active_section == 'conversation' ? 'primary' : 'default'">comments</v-btn>
                        <v-btn x-small 
                            @click="active_section = 'timeline'"
                            :color="active_section == 'timeline' ? 'primary' : 'default'">timeline</v-btn>
                    </div>
                    <v-divider></v-divider>
                    <div class="comment-section" v-if="active_section == 'conversation'">
                        <v-btn small text block @click="comment_dialog = true;">Write a comment</v-btn>
                    </div>
                    <v-divider></v-divider>
                    <div class="comment-trail" v-if="active_section == 'conversation'">
                        <div class="comment-trail-item" v-for="comment in job.comments?.slice(offset, 5*page)" v-bind:key="comment.id">
                            <div class="comment-avatar-cont">
                                <v-avatar
                                :color="comment.account_type == 'client' ? 'red' : 'primary'"
                                size="40"
                                >
                                <span class="white--text text-h5">{{ comment.name.substring(0,1) }}</span>
                                </v-avatar>
                            </div>
                            <div class="comment-content-cont">
                                <div class="author">{{ comment.name }}</div>
                                <div class="date">{{ comment.created_at }}</div>
                                <div class="content" v-html="comment.message"></div>
                            </div>
                        </div>
                        <div class="text-center" v-if="job.comments.lenth > 5">
                            <v-divider></v-divider>

                            <div class="mt-2">
                                <v-btn small 
                                    class="mr-2"
                                    :disabled="page == 1"
                                    @click="paginate(-1)" 
                                    >Prev</v-btn>
                                <v-btn small 
                                    :disabled="page >= total_pages"
                                    @click="paginate(1)"
                                    >Next
                                </v-btn>
                            </div>
                        </div>
                    </div>

                    <v-list dense v-if="active_section == 'timeline'">
                        <v-list-item two-line v-for="t in job.timeline" v-bind:key="t.id" @click="viewUpdateDetails(t)">
                            <v-list-item-content>
                                <v-list-item-title>{{ t.timestamp }}</v-list-item-title>
                                <v-list-item-subtitle>{{ t.description }} by {{ t.user.name }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
            </v-col>
        </v-row>
        <v-dialog width="500" v-model="show_note_details">
            <v-card>
                <v-card-title>Note Details</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-5">
                    <v-text-field outlined dense label="Notes" v-model="note.notes"></v-text-field>
                    <v-text-field outlined dense label="Report Link" v-model="note.report_link"></v-text-field>
                    <v-text-field outlined dense label="Run No." v-model="note.run_number"></v-text-field>
                    <v-text-field outlined dense label="Climate Zone" v-model="note.climate_zone"></v-text-field>
                    <v-text-field outlined dense label="Star Rating" v-model="note.star_rating"></v-text-field>
                    <v-text-field outlined dense label="Heating Load" v-model="note.heating_load"></v-text-field>
                    <v-text-field outlined dense label="Cooling Load" v-model="note.cooling_load"></v-text-field>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small block color="primary" @click="saveNote()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog width="500" v-model="show_staffs">
            <v-card>
                <staffs-list :staffs="staffs" @assignStaff="assign">
                    <template v-slot:subtitle>outlined 
                        <v-subheader v-if="job.job_status == 'allocated'">ASSIGN JOB {{ job.lbs_ref_no }}.</v-subheader>
                        <v-subheader v-if="job.job_status != 'allocated'">RE-ASSIGN JOB {{ job.lbs_ref_no }}. (Currently assigned to {{ job.staff.name }})</v-subheader>
                    </template>
                </staffs-list>
            </v-card>
        </v-dialog>
        <v-dialog v-model="comment_dialog" width="500">
            <v-card>
                <v-card-title>
                    Write your comment
                </v-card-title>
                <editor :content.sync="comment" @update="updateComment"></editor>
                <v-card-actions>
                    <v-btn small color="primary" block @click="postComment()" :disabled="$store.state.loading">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog max-width="1200" v-model="email_log_dialog">
            <v-card>
                <v-card-title>EMAIL LOG</v-card-title>
                <v-divider></v-divider>
                <v-row no-gutters>
                    <v-col cols="12" md="5">
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-select dense hide-details 
                                        outlined
                                        prepend-icon="mdi-close"
                                        @click:prepend="email_item_template.sec1 = null"
                                        :items="email_items.sec1"
                                        v-model="email_item_template.sec1">
                                    </v-select>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-select dense hide-details 
                                        outlined
                                        prepend-icon="mdi-close"
                                        @click:prepend="email_item_template.sec2 = null"
                                        :items="email_items.sec2"
                                        v-model="email_item_template.sec2">
                                    </v-select>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-select dense hide-details 
                                        outlined
                                        prepend-icon="mdi-close"
                                        @click:prepend="email_item_template.sec3 = null"
                                        :items="email_items.sec3"
                                        v-model="email_item_template.sec3">
                                    </v-select>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-select dense hide-details 
                                        outlined
                                        prepend-icon="mdi-close"
                                        @click:prepend="email_item_template.sec4 = null"
                                        :items="email_items.sec4"
                                        v-model="email_item_template.sec4">
                                    </v-select>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-select dense hide-details 
                                        outlined
                                        prepend-icon="mdi-close"
                                        @click:prepend="email_item_template.sec5 = null"
                                        :items="email_items.sec5"
                                        v-model="email_item_template.sec5">
                                    </v-select>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-select dense hide-details 
                                        outlined
                                        prepend-icon="mdi-close"
                                        @click:prepend="email_item_template.sec6 = null"
                                        :items="email_items.sec6"
                                        v-model="email_item_template.sec6">
                                    </v-select>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-select dense hide-details 
                                        outlined
                                        prepend-icon="mdi-close"
                                        @click:prepend="email_item_template.sec7 = null"
                                        :items="email_items.sec7"
                                        v-model="email_item_template.sec7">
                                    </v-select>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-select dense hide-details 
                                        outlined
                                        prepend-icon="mdi-close"
                                        @click:prepend="email_item_template.sec8 = null"
                                        :items="email_items.sec8"
                                        v-model="email_item_template.sec8">
                                    </v-select>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-select dense hide-details 
                                        outlined
                                        prepend-icon="mdi-close"
                                        @click:prepend="email_item_template.sec9 = null"
                                        :items="email_items.sec9"
                                        v-model="email_item_template.sec9">
                                    </v-select>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-select dense hide-details 
                                        outlined
                                        prepend-icon="mdi-close"
                                        @click:prepend="email_item_template.sec10 = null"
                                        :items="email_items.sec10"
                                        v-model="email_item_template.sec10">
                                    </v-select>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-select dense hide-details 
                                        outlined
                                        prepend-icon="mdi-close"
                                        @click:prepend="email_item_template.sec11 = null"
                                        :items="email_items.sec11"
                                        v-model="email_item_template.sec11">
                                    </v-select>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-divider></v-divider>
                    <v-col cols="12" md="7">
                        <editor :content.sync="email_text" @update="updateEmailLog" ref="editor"></editor>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-text-field class="mt-3" hide-details label="Send Email To:" outlined dense v-model="email_log_recipient" 
                        append-icon="mdi-send"
                        @click:append="sendEmailLog(email_text)">
                    </v-text-field>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog width="400" v-model="show_status">
            <v-card>
                <v-card-title>Job Status</v-card-title>
                <v-divider></v-divider>
                <v-list>
                    <v-list-item v-for="s in job_status_list" :key="s.value" @click="updateStatus(s.value)">
                        <v-list-item-icon>
                            <v-icon :color="job.job_status == s.value ? 'primary' : 'grey'">mdi-check-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title :class="job.job_status != s.value ? 'grey--text' : ''">{{ s.text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-dialog>

        <v-dialog width="500" v-model="changes_dialog">
            <v-card>
                <v-card-title>{{ update_details.user?.name}} - {{ update_details.timestamp }}</v-card-title>
                <v-card-text>
                    <p>{{ update_details.description }}</p>
                </v-card-text>
                <v-list dense v-if="update_details.old_val && update_details.new_val">
                    <v-list-item v-if="update_details.old_val?.client_name != update_details.new_val?.client_name">
                        <v-list-item-content>
                            <v-list-item-title>Client Name</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ update_details.old_val?.client_name }} &emsp;>&emsp;{{ update_details.new_val?.client_name }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="update_details.old_val?.priority != update_details.new_val?.priority">
                        <v-list-item-content>
                            <v-list-item-title>Priority</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ update_details.old_val?.priority }} &emsp;>&emsp;{{ update_details.new_val?.priority }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="update_details.old_val?.client_ref_no != update_details.new_val?.client_ref_no">
                        <v-list-item-content>
                            <v-list-item-title>Contact Number</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ update_details.old_val?.client_ref_no }} &emsp;>&emsp;{{ update_details.new_val?.client_ref_no }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="update_details.old_val?.job_address != update_details.new_val?.job_address">
                        <v-list-item-content>
                            <v-list-item-title>Job Address</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ update_details.old_val?.job_address }} &emsp;>&emsp;{{ update_details.new_val?.job_address }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="update_details.old_val?.job_type != update_details.new_val?.job_type">
                        <v-list-item-content>
                            <v-list-item-title>Job Type Request</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ update_details.old_val?.job_type }} &emsp;>&emsp;{{ update_details.new_val?.job_type }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="update_details.old_val?.version != update_details.new_val?.version">
                        <v-list-item-content>
                            <v-list-item-title>NCC Compliance</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ update_details.old_val?.version }} &emsp;>&emsp;{{ update_details.new_val?.version }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="update_details.old_val?.notes != update_details.new_val?.notes">
                        <v-list-item-content>
                            <v-list-item-title>Notes</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ update_details.old_val?.notes }} &emsp;>&emsp;{{ update_details.new_val?.notes }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
    .pointer {
        cursor: pointer;
    }
    .text-blue {
        color : blue;
    }
    .comment-trail {
        padding : 10px 0;
    }
    .comment-trail-item {
        display: flex;
    }
    .comment-trail-item .author {
        font-weight : bold;
    }
    .comment-trail-item .date {
        font-size: 12px;
    }
    .comment-trail-item .content {
        font-size: 13px;
    }
    .comment-content-cont {
        padding : 0px 15px;
    }
</style>
<script>
import axios from 'axios';
import { globalMixins } from '@/mixins/globalMixins';
import CheckerList from '@/components/CheckerList.vue';
import StaffsList from '@/components/StaffsList.vue';
import Editor from '@/components/Editor.vue';
export default 
{
    name : 'JobDetails',
    mixins : [globalMixins],
    components : {
        CheckerList,
        StaffsList,
        Editor
    },
    watch: {
        'job.comments'(newVal) {
            this.total_pages = Math.ceil(newVal.length / 5);
            console.log(this.total_pages);
        },
        email_item_template : {
            handler(newVal, oldVal) {
                this.previewEmail();
            },
            deep : true
        }
    },
    data() {
        return {
            tab : 'tab1',
            new_frate : null,
            new_db : null,
            new_private_file : null,
            job : {
                client : {}
            },
            show_checkers : false,
            staffs : [],
            validator : null,
            user : {},
            show_staffs : false,
            comment_dialog: false,
            comment : null,
            active_section : 'conversation',
            page : 1,
            offset : 0,
            total_pages : 0,
            note : {},
            show_note_details : false,
            email_text : null,
            email_log_dialog : false,
            email_log_recipient : null,
            show_status : false,
            job_status_list : [],
            changes_dialog : false,
            update_details : {},
            email_items : [],
            email_item_template: {
                sec1: null,
                sec2: null,
                sec3: null,
                sec4: null,
                sec5: null,
                sec6: null,
                sec7: null,
                sec8: null,
                sec9: null,
                sec10: null,
                sec11: null,
            }

        }
    },
    mounted() {
        this.email_items = this.bph_email_items;
        this.$store.dispatch('loading', false);
        this.user = JSON.parse(this.$store.state.user);
        this.job_status_list = this.bph_status;
        this.reload();
    },
    methods : {
        reload() {
            axios({
                url : process.env.VUE_APP_API_URL + '/jobs/' + this.$route.params.id,
                method : 'GET',
                params : {read : this.$route.query.read, nid : this.$route.query.nid},
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.job = response.data;

                if(this.job.job_status == 'submitted for checking' && this.job.validator.id == this.$store.getters.getUser.id) {
                    this.$router.push({name : 'JobDetailsCheck'});
                }
            });
        },
        reAssignJob() {
            this.$store.dispatch('loading', true);
            axios({
                url : process.env.VUE_APP_API_URL + '/staffs',
                method : 'GET',
                params : {exclude : this.job.staff.id},
                headers : {
                    'Accept' : 'application/json',
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.show_staffs = true;
                this.staffs = response.data;
                this.$store.dispatch('loading', false);
            }).catch(err => {
                this.$store.dispatch('loading', false);
            });
        },
        assign(id) {
            this.$store.dispatch('loading', true);
            var action = this.job.job_status == 'allocated' ? 'assigned' : 're-assigned'
            axios({
                url : process.env.VUE_APP_API_URL + '/jobs/' + this.job.id + '/assign',
                method : 'POST',
                data : {staff_id : id},
                params : {action : action},
                headers : {
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.reload();
                this.show_staffs = false;
                this.$store.dispatch('loading', false);
            }).catch(err => {
                this.$store.dispatch('loading', false);
            });
        },
        updateComment(comment) {
            this.comment = comment;
        },
        postComment() {
            this.$store.commit('changeLoading', true);
            axios({
                url : this.$store.state.api_url + '/jobs/' + this.$route.params.id + '/conversations',
                method : 'POST',
                data : {message : this.comment, job_id : this.$route.params.id},
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.$store.commit('changeLoading', false);
                this.comment_dialog = false;
                this.job.comments.unshift(res.data);
            }).catch(err => {
                this.$store.commit('changeLoading', false);
            })
        },
        addNote() {
            this.note = {
                id : 0
            };
            this.show_note_details = true;
        },
        editNote(n) {
            this.note = Object.assign({}, n);
            this.show_note_details = true;
        },
        deleteNote(idx, n) {
            if(confirm('Are you sure?')) {
                axios({
                    url: process.env.VUE_APP_API_URL + '/jobs/' + this.$route.params.id + '/notes/' + n.id,
                    method : 'DELETE',
                    data : this.note,
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.job.job_notes.splice(idx, 1);
                });
            }
        },
        saveNote() {
            if(this.note.id == 0) {
                axios({
                    url: process.env.VUE_APP_API_URL + '/jobs/' + this.$route.params.id + '/notes',
                    method : 'POST',
                    data : this.note,
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.job.job_notes = res.data.data;
                    this.$forceUpdate();
                    this.show_note_details = false;
                });
            }
            else {
                axios({
                    url : process.env.VUE_APP_API_URL + '/jobs/' + this.$route.params.id + '/notes/' + this.note.id,
                    method : 'PUT',
                    data : this.note,
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.job.job_notes = res.data.data;
                    this.$forceUpdate();

                    this.show_note_details = false;
                })
            }
        },
        assignJob() {
            this.$store.dispatch('loading', true);
            axios({
                url : process.env.VUE_APP_API_URL + '/staffs',
                method : 'GET',
                headers : {
                    'Accept' : 'application/json',
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.show_staffs = true;
                this.staffs = response.data.map(s => {
                    s.assigned_jobs_preview = s.assigned_jobs.map(j => {
                        return 'LBS Ref No. ' + j.lbs_ref_no + ' [' + j.deadline + ']' 
                    }).join("\n");

                    return s;
                });

                this.$store.dispatch('loading', false);
            }).catch(err => {
                this.$store.dispatch('loading', false);
            });
        },
        openEmailLog() {
            let job = Object.assign({}, this.job);
            this.email_log_recipient = job.client.email;

            let date = new Date();
            let text = `<p>Dear ${job.client.name}</p>`;
            text += '<p></p>';
            text += `<p style="text-align:center;">${ date.toLocaleString() }</p>`;
            text += `<p style="text-align:center;">${ job.version } Working Dwgs EA for ${job.job_number} ${job.company}</p>`;
            text += '<p></p>';
            text += `<p style="text-align:center;">- Climate Zone : ${job.job_note?.climate_zone} -</p>`;
            this.email_text = text;
            this.email_log_dialog = true;
        },
        previewEmail() {
            let job = Object.assign({}, this.job);
            let date = new Date();

            let text = `<p>Dear ${job.client.name}</p>`;
            text += '<p></p>';
            text += `<p style="text-align:center;">${ date.toLocaleString() }</p>`;
            text += `<p style="text-align:center;">${ job.version } Working Dwgs EA for ${job.job_number} ${job.company}</p>`;
            text += '<p></p>';
            text += `<p style="text-align:center;">- Climate Zone -</p>`;
            if(this.email_item_template.sec1) {
                text += `<p style="text-align:center;">${this.email_item_template.sec1}</p>`;
            }
            text += '<p></p>';
            text += `<p style="text-align:center;">Your current thermal loads are as below: Heating Load - ${job.job_note?.heating_load}  Cooling Load - ${job.job_note?.cooling_load}</p>`;
            text += '<p></p>';
            for(let key in this.email_item_template) {
                if(key != 'sec1') {
                    if(this.email_item_template[key]) {
                        text += `<p style="text-align:center;">&bull;&nbsp;${this.email_item_template[key]}</p>`;
                    }
                }
            }
            

            this.email_text = text;
            this.$refs.editor.setEditorContent(this.email_text);
        },
        updateEmailLog(text) {
            this.email_text = text;
        },
        updateStatus(status) {
            if(status == 'completed') {
                if(!confirm('Are you sure?')) {
                    return;
                }
            }

            if(status == 'LOG') {
                axios({
                    url : process.env.VUE_APP_API_URL + '/jobs/bph/check-log-status',
                    method : 'GET',
                    params : {id : this.job.id},
                    data : {status : status},
                    headers : {
                        'Accept' : 'application/json',
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    if(res.data.id == undefined) {
                        axios({
                            url : process.env.VUE_APP_API_URL + '/jobs/'+ this.job.id +'/status',
                            method : 'PUT',
                            data : {job_status : status},
                            headers : {
                                'Accept' : 'application/json',
                                'Authorization' : 'Bearer ' + this.$store.getters.getToken
                            }
                        }).then(res => {
                            this.reload(this.category_id);
                            this.show_status = false;
                        }).catch(err => {
                            alert(JSON.stringify(err));
                        })
                    }
                    else {
                        alert('Log Status has already been assigned to another job.');
                    }
                });
            }
            else {
                axios({
                    url : process.env.VUE_APP_API_URL + '/jobs/'+ this.job.id +'/status',
                    method : 'PUT',
                    data : {job_status : status},
                    headers : {
                        'Accept' : 'application/json',
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.reload(this.category_id);
                    this.show_status = false;

                    if(status == 'completed') {
                        if(confirm('Job has been completed.\nDo you want to notify the client?')) {
                            this.sendClientNotification(this.job.id).then(res => {
                                alert('Email notification has been sent');
                            }).catch(err => {
                                alert(JSON.stringify(err));
                            })
                        }
                    }
                }).catch(err => {
                    alert(JSON.stringify(err));
                })
            }
        },
        sendEmailLog(text) {
           if(confirm('Are you sure you want to send this email?')) {
                axios({
                    url : process.env.VUE_APP_API_URL + `/jobs/${this.job.id}/email-log/send`,
                    method : 'POST',
                    data : {recipient : this.email_log_recipient, content : text, subject : `${this.job.version} ${this.job.job_type} ${this.job.job_number} ${this.job.client_name}`},
                    headers : {
                        'Accept' : 'application/json',
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    alert(res.data.message);
                })
           }
        },
        viewUpdateDetails(details) {
            this.changes_dialog = true;
            this.update_details = details;
        },
        selectNote(job_note) {
            axios({
                url : process.env.VUE_APP_API_URL + `/jobs/${this.job.id}/select-job-note`,
                method : 'PUT',
                data : {
                    note_id : job_note.id
                },
                headers : {
                    'Accept' : 'application/json',
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                alert(res.data.message);
                this.job = res.data.data;
            })
        }
    }
}
</script>