<template>
    <div class="pa-5">
        <v-alert
            icon="mdi-information-outline"
            prominent
            text
            type="info"
            v-if="file_groups.length == 0"
            >
            No Records Found
        </v-alert>
        <div class="mb-5">
            <v-btn small color="primary" @click="createGroup()" v-if="user.account_type == 'admin'">Create a new group</v-btn>
        </div>
        <v-row>
            <v-col cols="12" sm="6" md="4" v-for="g in file_groups" :key="g.id">
                <v-card color="primary">
                    <v-card-title class="white--text">
                        {{ g.name }}
                        <v-btn text dark x-small @click="showGroupRestriction(g)">
                            <v-icon small>{{ share_icon[g.restriction] }}</v-icon>
                        </v-btn>

                        <v-spacer></v-spacer>
                        <v-btn text dark x-small v-if="user.account_type == 'admin'" @click="editGroup(g)">edit</v-btn>
                        <v-btn text dark x-small v-if="user.account_type == 'admin'" @click="deleteGroup(g)">delete</v-btn>
                    </v-card-title>
                    <v-card-subtitle class="white--text">
                        {{ g.description || 'No description' }}
                    </v-card-subtitle>
                    <v-divider></v-divider>
                    <v-list dense>
                        <v-list-item v-for="f in g.files" :key="f.id">
                            <v-list-item-title :title="f.description">
                                {{ f.name }}
                            </v-list-item-title>
                            <v-spacer></v-spacer>
                            <v-btn x-small text @click="viewFileLink(f)">
                                <v-icon small>mdi-link</v-icon>
                            </v-btn>
                            <v-btn x-small text @click="editFileRecord(f)" v-if="user.account_type == 'admin'">
                                <v-icon small>mdi-pencil-circle</v-icon>
                            </v-btn>
                            <v-btn x-small text @click="deleteFileRecord(f)" v-if="user.account_type == 'admin'">
                                <v-icon small>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-list-item>
                        <v-list-item v-if="g.files.length == 0">
                            <v-list-item-title>No files found</v-list-item-title>
                        </v-list-item>
                        
                        <v-divider v-if="user.account_type == 'admin'"></v-divider>
                        <v-list-item v-if="user.account_type == 'admin'">
                            <v-spacer></v-spacer>
                            <v-btn small @click="addFileRecord(g.id)" text color="primary">add New Link</v-btn>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog width="500" v-model="view_group_details">
            <v-card>
                <v-card-title>File Group/Category Details</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-5">
                    <v-text-field label="Group Name" v-model="file_group.name"></v-text-field>
                    <v-textarea rows="3" label="Description" v-model="file_group.description"></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" block @click="saveGroup()">Save Group</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog width="500" v-model="view_file_details">
            <v-card>
                <v-card-title>File Details</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <div class="pt-5">
                        <v-text-field outlined dense label="Name" v-model="file.name"></v-text-field>
                        <v-text-field outlined dense label="File Link" v-model="file.file_link"></v-text-field>
                        <v-textarea rows="2" outlined dense label="Description" v-model="file.description"></v-textarea>
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn small block color="primary" @click="saveFileRecord()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog width="500" v-model="view_file_group_restriction">
            <v-card>
                <v-card-title>Group Restriction</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <div class="pt-5">
                        <v-select 
                            label="Restriction"
                            :items="['public', 'private', 'shared']" v-model="file_group.restriction"
                            hint="Public - Client/LDS, Private - LDS, Shared - Selected Clients"
                            persistent-hint
                            class="mb-5"
                            >
                        </v-select>
                        <v-select
                            v-if="file_group.restriction == 'shared'"
                            v-model="file_group.shared_to"
                            :items="clients"
                            item-value="id"
                            item-text="name_category"
                            label="Clients"
                            multiple
                            chips
                        ></v-select>
                        <v-checkbox
                            v-if="file_group.restriction == 'shared'"
                            v-model="select_all"
                            label="Select All"
                            @change="file_group.shared_to = select_all ? clients.map(c => c.id) : []"
                        ></v-checkbox>
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn small block color="primary" @click="saveFileGroupRestriction()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name : 'Dashboard',
    data() {
        return {
            search : null,
            view_file_details : false,
            view_group_details : false,
            view_file_group_restriction : false,
            files : [],
            file : {},
            file_groups : [],
            file_group : {},
            user : {},
            select_all : false,
            share_icon : {
                'public' : 'mdi-earth',
                'private' : 'mdi-alpha-l-box-outline',
                'shared' : 'mdi-account-multiple'
            },
            clients : []
        }
    },
    watch : {
        'file_group.shared_to'(val) {
            if(val.length == this.clients.length) {
                this.select_all = true;
            }
            else {
                this.select_all = false;
            }
        }
    },
    mounted() {
        this.user = JSON.parse(this.$store.state.user);
        this.refreshFiles();

        axios({
            url : process.env.VUE_APP_API_URL + '/clients',
            method : 'GET',
            headers : {
                'Authorization' : 'Bearer ' + this.$store.getters.getToken
            }
        }).then(res => {         
            this.clients = res.data.data.map(c => {
                if(c.company_category_id == 1) {
                    c.name_category = c.name + '(LBS)';
                }
                else if(c.company_category_id == 2) {
                    c.name_category = c.name + '(BPH)';
                }
                else if(c.company_category_id == 3) {
                    c.name_category = c.name + '(B1)';
                }
                return c;
            });
        });
    },
    methods : {
        refreshFiles() {
            axios({
                url : process.env.VUE_APP_API_URL + '/file-directory/file-groups',
                method : 'GET',
                headers : {
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                let output = res.data;
                this.file_groups = output.data;
            })
        },
        createGroup() {
            this.file_group = {};
            this.view_group_details = true;
        },
        saveGroup(){
            if(this.file_group.id != undefined) {
                axios({
                    url : process.env.VUE_APP_API_URL + '/file-directory/file-groups/' + this.file_group.id,
                    method : 'PUT',
                    data : this.file_group,
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    let output = res.data;
                    this.view_group_details = false;
                    this.refreshFiles();
                })
            }
            else {
                axios({
                    url : process.env.VUE_APP_API_URL + '/file-directory/file-groups',
                    method : 'POST',
                    data : this.file_group,
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    let output = res.data;
                    this.view_group_details = false;
                    this.refreshFiles();
                })
            }
        },
        addFileRecord(id) {
            this.file = {
                group_id : id
            };
            this.view_file_details = true;
        },
        viewFileLink(file) {
            window.open(file.file_link, "blank");
        },
        editFileRecord(file) {
            this.file = Object.assign({}, file);
            this.view_file_details = true;
        },
        saveFileRecord() {
            if(this.file.id == undefined) {
                axios({
                    url : process.env.VUE_APP_API_URL + '/file-directory/files',
                    method : 'POST',
                    data : this.file,
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.refreshFiles();
                    this.view_file_details = false;
                }).catch(err => {
                    if(err.response.status == 400) {
                        alert(err.response.data.message);
                    }
                });
            }
            else {
                axios({
                    url : process.env.VUE_APP_API_URL + '/file-directory/files/' + this.file.id,
                    method : 'PUT',
                    data : this.file,
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.refreshFiles();
                    this.view_file_details = false;
                }).catch(err => {
                    if(err.response.status == 400) {
                        alert(err.response.data.message);
                    }
                });
            }
        },
        deleteFileRecord(file) {
            if(confirm("Are you sure?")) {
                axios({
                    url : process.env.VUE_APP_API_URL + '/file-directory/files/' + file.id,
                    method : 'DELETE',
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    let output = res.data;
                    this.refreshFiles();
                })
            }
        },
        editGroup(g) {
            this.file_group = Object.assign({}, g);
            this.view_group_details = true;
        },
        deleteGroup(g) {
            if(confirm("Are you sure?")) {
                axios({
                    url : process.env.VUE_APP_API_URL + '/file-directory/file-groups/' + g.id,
                    method : 'DELETE',
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    let output = res.data;
                    this.refreshFiles();
                    alert('File group deleted');
                })
            }
        },
        showGroupRestriction(g) {
            if(this.user.account_type != 'admin') {
                if(g.restriction == 'private') {
                    alert('This file group is private and can only be accessed by LDS');
                    return;
                }
                if(g.restriction == 'public') {
                    alert('This file group is public and can be accessed by LDS and Clients');
                    return;
                }
                return;
            }
            this.file_group = Object.assign({}, g);
            this.view_file_group_restriction = true;
        },
        saveFileGroupRestriction() {
            axios({
                url : process.env.VUE_APP_API_URL + '/file-directory/file-groups/' + this.file_group.id + '/restriction',
                method : 'PUT',
                data : this.file_group,
                headers : {
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.refreshFiles();
                this.view_file_group_restriction = false;
            });
        }
    }
}
</script>