import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token : localStorage.getItem('token') || '',
    user : localStorage.getItem('user') || '',
    loading : false,
    host_url : process.env.VUE_APP_HOST_URL,
    api_url : process.env.VUE_APP_API_URL,
    notifications: []
  },
  getters: {
    getToken (state) {
      return state.token;
    },
    getUser(state) {
      return JSON.parse(state.user);
    },
    getNotifications(state) { // Add getter for notifications
      return state.notifications;
    }
  },
  mutations: {
    changeToken(state, token) {
      localStorage.setItem('token', token);
      state.token = token;
    },
    changeUser(state, user) {
      localStorage.setItem('user', JSON.stringify(user));
      state.user = JSON.stringify(user);
    },
    changeLoading(state, loading) {
      state.loading = loading;
    },
    setNotifications(state, notifications) { // Add mutation to set notifications
      state.notifications = notifications;
    }
  },
  actions: {
    login({commit}, info) {
      commit('changeToken', info.token);
      commit('changeUser', info.user)
    },
    loading({commit}, loading) {
      commit('changeLoading', loading);
    },
    async fetchNotifications({commit, state}) { // Add action to fetch notifications
      try {
        const response = await axios.get(`${state.api_url}/notifications`, {
          headers: {
            'Authorization': `Bearer ${state.token}`
          }
        });
        commit('setNotifications', response.data);
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
      }
    }
  },
  modules: {
  }
})
