<template>
    <div class="pa-5">
        <v-row align="center">
            <v-col cols="12" md="3">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-row>
                    <v-col>
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="datefrom"
                                    label="From"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="datefrom"
                            @input="menu = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dateto"
                                label="To"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="dateto"
                            @input="menu2 = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6" class="text-right">
                <v-btn-toggle>
                    <v-btn small 
                        key="all"
                        @click="changeStatusFilter('all')"
                        :color="job_status == 'all' ? 'active' : ''">
                        All ({{ all_jobs['all'].length || 0 }})
                    </v-btn>
                    <v-btn small 
                        key="allocated"
                        @click="changeStatusFilter('allocated')"
                        :color="job_status == 'allocated' ? 'active' : ''">
                        Allocated ({{ all_jobs['allocated'].length || 0 }})
                    </v-btn>

                    <!-- <v-btn small 
                        key="on-going"
                        @click="changeStatusFilter('on-going')"
                        :color="job_status == 'on-going' ? 'active' : ''">
                        Accepted({{ all_jobs['on-going'].length || 0 }})
                    </v-btn> -->

                    <v-btn small 
                        key="validate"
                        @click="changeStatusFilter('validate')"
                        :color="job_status == 'validate' ? 'active' : ''">
                        For Checking ({{ all_jobs['validate'].length || 0 }})
                    </v-btn>

                    <v-btn small 
                        key="completed"
                        @click="changeStatusFilter('completed')"
                        :color="job_status == 'completed' ? 'active' : ''">
                        Completed ({{ all_jobs['completed'].length || 0 }})
                    </v-btn>

                    <v-btn small 
                        key="cancelled"
                        @click="changeStatusFilter('cancelled')"
                        :color="job_status == 'cancelled' ? 'active' : ''">
                        Cancelled ({{ all_jobs['cancelled'].length || 0 }})
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <div class="text-right mb-3">
            <v-btn text @click="show_companies = true" color="primary"><v-icon>mdi-plus</v-icon> Create Job</v-btn>
        </div>
        <v-data-table
            :headers="headers"
            :items="jobs"
            class="elevation-1 table-sm"
            :search="search"
            >
            <template v-slot:item="{item}">
                <tr v-if="category_id == 1">
                    <td>{{ item.date_submitted }}</td>
                    <td>{{ item.deadline }}</td>
                    <td>
                        <v-badge
                            v-if="item.job_status != 'completed'"
                            :color="item.time_remaining_status_color"
                            :content="item.time_remaining_txt + ' ' + item.time_remaining_status"
                            inline
                        >
                        </v-badge>

                        <v-badge
                            v-if="item.job_status == 'completed'"
                            color="primary"
                            content="Completed"
                            inline
                        >
                        </v-badge>
                    </td>
                    <td>{{ item.company }}</td>
                    <td>{{ item.job_type }}</td>
                    <td>{{ item.version }}</td>
                    <td>{{ item.state }}</td>
                    <td>
                        <v-btn block text 
                            :title="item.staff ? item.staff.name : ''"
                            @click="showStaffs(item)" x-small :color="item.staff ? 'primary' : 'default'">
                            {{ item.staff ? getInitials(item.staff.name) : '...' }}
                        </v-btn>
                    </td>
                    <td>{{ item.priority }}</td>
                    <td>
                        {{ item.lbs_ref_no }}
                    </td>
                    <td>
                        <v-rating
                            v-model="item.plan_complexity"
                            color="primary"
                            small
                            length="3"
                            @input="changeComplexity(item);"
                            >
                        </v-rating>
                    </td>
                    <td>
                        <v-btn text block 
                            :title="item.validator ? item.validator.name : ''"
                            @click="showStaffs(item, 'checker')" x-small :color="item.validator ? 'primary' : 'default'">
                            {{ item.validator ? getInitials(item.validator.name) : '...' }}
                        </v-btn>
                    </td>
                    <td>
                        <v-btn x-small @click="getStatusList(item)">{{ item.job_status }}</v-btn>
                    </td>
                    <td>
                        <a @click="showNotes(item)" v-if="item.notes" :title="item.notes">Notes</a>
                    </td>
                    <td>
                        <v-btn small icon :color="colorIndex(item.job_status)"
                            @click="goToDetails(item)">
                            <v-icon>
                                mdi-information
                            </v-icon>
                        </v-btn>
                    </td>
                </tr>
                <tr v-if="category_id > 1">
                    <td>{{ item.date_submitted }}</td>
                    <td>{{ item.priority }}</td>
                    <td>{{ item.version }}</td>
                    <td>{{ item.job_type }}</td>
                    <td>{{ item.job_number }}</td>
                    <td>{{ item.company }}</td>
                    <td>{{ item.job_note?.star_rating }}</td>
                    <td>{{ item.job_note?.climate_zone }}</td>
                    <td>{{ item.job_address }}</td>
                    <td>
                        <v-btn block text 
                            :title="item.staff ? item.staff.name : ''"
                            @click="showStaffs(item)" x-small :color="item.staff ? 'primary' : 'default'">
                            {{ item.staff ? getInitials(item.staff.name) : '...' }}
                        </v-btn>
                    </td>
                    <td>
                        <v-btn x-small @click="getStatusList(item)">{{ item.job_status }}</v-btn>
                    </td>
                    <td>
                        <v-btn small icon :color="colorIndex(item.job_status)"
                            @click="goToDetails(item)">
                            <v-icon>
                                mdi-information
                            </v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <v-dialog width="500" v-model="show_staffs">
            <v-card :disabled="$store.state.loading">
                <v-progress-linear
                indeterminate
                color="primary"
                v-if="$store.state.loading"
                ></v-progress-linear>
                <v-card-title>
                    {{ job.assignment_type == 'assignee' ? 'Assign Job' : 'Assign Checker' }}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-subtitle class="pt-3">
                    <div>
                        <strong>Address:</strong> {{ job.job_address }}
                    </div>
                    <div>
                        <strong>Job Type:</strong> {{ job.job_type }}
                    </div>
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-list dense v-if="job.assignment_type == 'assignee'">
                    <v-list-item v-for="s in staffs" :key="s.id" @click="assignJob(s)">
                        <v-list-item-icon>
                            <v-icon :color="job.staff ? (job.staff.id == s.id ? 'primary' : 'grey') : ''">mdi-check-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title :class="job.staff ? (job.staff.id != s.id ? 'grey--text' : '') : ''">{{ s.name }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list dense v-if="job.assignment_type == 'checker'">
                    <v-list-item v-for="s in staffs" :key="s.id" @click="assignJobChecker(s)">
                        <v-list-item-icon>
                            <v-icon :color="job.validator ? (job.validator.id == s.id ? 'primary' : 'grey') : ''">mdi-check-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title :class="job.validator ? (job.validator.id != s.id ? 'grey--text' : '') : ''">{{ s.name }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-card-actions v-if="job.assignment_type == 'assignee' && job.staff">
                    <v-btn text block small color="error" @click="unassignJob(job)">
                        Remove Assignee
                    </v-btn>
                </v-card-actions>
                <v-card-actions v-if="job.assignment_type == 'checker' && job.validator">
                    <v-btn text block small color="error" @click="unassignJob(job)">
                        Remove Checker
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog width="600" v-model="show_notes">
            <v-card>
                <v-card-title>Notes</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-5">
                    <p v-html="job.notes"></p>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog width="400" v-model="show_status">
            <v-card>
                <v-card-title>Job Status</v-card-title>
                <v-divider></v-divider>
                <v-list>
                    <v-list-item v-for="s in job_status_list" :key="s.value" @click="updateStatus(s.value)">
                        <v-list-item-icon>
                            <v-icon :color="job.job_status == s.value ? 'primary' : 'grey'">mdi-check-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title :class="job.job_status != s.value ? 'grey--text' : ''">{{ s.text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-dialog>

        <v-dialog width="300" v-model="show_companies">
            <v-card>
                <v-card-title>Company</v-card-title>
                <v-divider></v-divider>
                <v-list>
                    <v-list-item :to="{name : 'NewJobLBS'}">
                        <v-list-item-content>
                            <v-list-item-title>LBS</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{name : 'NewJobBPH'}">
                        <v-list-item-content>
                            <v-list-item-title>BPH</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{name : 'NewJobB1'}">
                        <v-list-item-content>
                            <v-list-item-title>B1</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
    .table-sm tr td {
        font-size: 12px !important;
    }
</style>
<script>
    import axios from 'axios';
    import { globalMixins } from '@/mixins/globalMixins';
    import BottomSheetJobDetails from '@/components/BottomSheetJobDetails.vue';
    import StaffsList from '@/components/StaffsList.vue';
    export default {
        name : 'ListView',
        mixins : [globalMixins],
        components : {
            BottomSheetJobDetails,
            StaffsList
        },
        data() {
            return {
                menu : null,
                menu2 : null,
                search: '',
                datefrom : null,
                dateto : null,
                show_details : false,
                show_status : false,
                category_id : 0,
                job_status_list : [],
                headers : [],
                job_status : 'allocated',
                all_jobs : {
                    'allocated' : [],
                    'on-going' : [],
                    'validate' : [],
                    'completed' : []
                },
                jobs : [],
                job : {},
                job_timelines : [],
                staffs : [],
                show_staffs : false,
                show_notes : false,
                button_type : '',
                completed_loading : true,
                first_load : true,
                show_companies : false
            }
        },
        mounted() {
            let date = new Date();
            this.dateto = this.formatDate(new Date(date));

            let datefrom = date.setDate(date.getDate() - 30);
            this.datefrom = this.formatDate(new Date(datefrom));

            this.category_id = this.$route.params.type;

            if(this.category_id == 1) {
                this.headers = this.lbs_headers;
                console.log(this.lbs_headers);
                this.job_status_list = this.lbs_status;
            }
            else if(this.category_id > 1) {
                this.headers = this.bph_headers;
                this.job_status_list = this.bph_status;
            }
            
            this.reload(this.category_id);
            this.loadStaffs();
        },
        watch: {
            '$route.params.type': function(newVal, oldVal) {
                this.category_id = newVal;
                
                if(this.category_id == 1) {
                    this.headers = this.lbs_headers;
                    console.log(this.lbs_headers);
                    this.job_status_list = this.lbs_status;
                }
                else if(this.category_id > 1) {
                    this.headers = this.bph_headers;
                    this.job_status_list = this.bph_status;
                }
                this.reload(this.category_id);
            },
            'datefrom' : function (newVal, oldVal) {
                if(!this.first_load) {
                    this.reload(this.category_id);  
                }
                
            },
            'dateto' : function (newVal, oldVal) {
                if(!this.first_load) {
                    this.reload(this.category_id);  
                }
            }
        },
        methods : {
            loadStaffs() {
                axios({
                    url : process.env.VUE_APP_API_URL + '/staffs',
                    method : 'GET',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(response => {
                    this.staffs = response.data;
                }).catch(err => {
                    alert(JSON.stringify(err));
                });
            },
            formatDate(date) {
                let year = date.getFullYear();
                let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
                let day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            },
            reload(category_id) {
                let start = this.datefrom + ' 00:00:00';
                let end = this.dateto + ' 23:59:59';
                axios({
                    url : process.env.VUE_APP_API_URL + '/jobs',
                    method : 'GET',
                    params : {category_id : category_id, starttime : start, endtime : end},
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(response => {
                    this.all_jobs = response.data;
                    this.changeStatusFilter(this.job_status);
                    this.first_load = false;
                }).catch(err => {
                    console.log(err);
                    this.first_load = false;
                });
            },
            showStaffs(job, assignment_type='assignee') {
                this.job = job;
                this.job.assignment_type = assignment_type;
                this.show_staffs = true;
            },
            assignJob(staff) {
                this.$store.dispatch('loading', true);
                var action = this.job.job_status == 'allocated' ? 'assigned' : 're-assigned'
                axios({
                    url : process.env.VUE_APP_API_URL + '/jobs/' + this.job.id + '/assign',
                    method : 'POST',
                    data : {staff_id : staff.id},
                    params : {action : action},
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.$set(this.jobs[this.job.idx], 'staff', res.data.staff);
                    this.$store.dispatch('loading', false);
                    this.show_staffs = false;
                }).catch(err => {
                    this.$store.dispatch('loading', false);
                });
            },
            assignJobChecker(staff) {
                this.$store.dispatch('loading', true);
                axios({
                    url : process.env.VUE_APP_API_URL + '/jobs/' + this.job.id + '/assign-validator',
                    method : 'POST',
                    data : {validator_id : staff.id},
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.$set(this.jobs[this.job.idx], 'validator', res.data.validator);
                    this.$store.dispatch('loading', false);
                    this.show_staffs = false;
                }).catch(err => {
                    this.$store.dispatch('loading', false);
                });
            },
            unassignJob(job) {
                if(!confirm('Are you sure?')) {
                    return;
                }
                if(job.assignment_type == 'assignee') {
                    this.$store.dispatch('loading', true);
                    var action = job.job_status == 'allocated' ? 'assigned' : 're-assigned'
                    axios({
                        url : process.env.VUE_APP_API_URL + '/jobs/' + job.id + '/assign',
                        method : 'POST',
                        data : {staff_id : null},
                        params : {action : action},
                        headers : {
                            'Authorization' : 'Bearer ' + this.$store.getters.getToken
                        }
                    }).then(res => {
                        this.$set(this.jobs[this.job.idx], 'staff', null);
                        this.$store.dispatch('loading', false);
                        this.show_staffs = false;
                    }).catch(err => {
                        this.$store.dispatch('loading', false);
                    });
                }
                else if(job.assignment_type == 'checker') {
                    this.$store.dispatch('loading', true);
                    axios({
                        url : process.env.VUE_APP_API_URL + '/jobs/' + job.id + '/assign-validator',
                        method : 'POST',
                        data : {validator_id : null},
                        headers : {
                            'Authorization' : 'Bearer ' + this.$store.getters.getToken
                        }
                    }).then(res => {
                        this.$set(this.jobs[this.job.idx], 'validator', null);
                        this.$store.dispatch('loading', false);
                        this.show_staffs = false;
                    }).catch(err => {
                        this.$store.dispatch('loading', false);
                    });
                }
            },
            changeComplexity(job) {
                this.$store.dispatch('loading', true);
                axios({
                    url : process.env.VUE_APP_API_URL + '/jobs/' + job.id + '/plan-complexity',
                    method : 'PUT',
                    data : {plan_complexity : job.plan_complexity},
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.$set(this.jobs[job.idx], 'plan_complexity', res.data.plan_complexity);
                    this.$store.dispatch('loading', false);
                    this.show_staffs = false;
                }).catch(err => {
                    this.$store.dispatch('loading', false);
                });
            },
            getInitials(name) {
                let names = name.split(" ");
                let initials = [];
                names.forEach(n => {
                    if(n) {
                        initials.push(n[0]);
                    }
                })
                return initials.join("");
            },
            changeStatusFilter(status) {
                this.job_status = status;
                this.jobs = this.all_jobs[this.job_status].map((d, index) => {
                    d.idx = index;
                    d.time_remaining = d.time_remaining.replace(',', '');
                    
                    if(d.time_remaining >= 24) {
                        var day = Math.floor(d.time_remaining / 24);
                        var hour = (d.time_remaining % 1) * 24;
                        d.time_remaining_txt = day + 'd, ' + Math.floor(hour) + 'h';
                    }
                    else if(d.time_remaining < 24 && d.time_remaining > 0) {
                        var abs = Math.abs(d.time_remaining);
                        var mins = Math.abs((abs % 1)) * 60;
                        var hours = Math.floor(abs);
                        d.time_remaining_txt = hours + 'h, ' + Math.floor(mins) + 'm';
                    }
                    else {
                        var abs = Math.abs(d.time_remaining);
                        if(abs >= 24) {
                            var day = Math.floor(abs / 24);
                            var hour = (abs % 1) * 24;
                            d.time_remaining_txt = day + 'd, ' + Math.floor(hour) + 'h';
                        }
                        else {
                            var mins = Math.abs((abs % 1)) * 60;
                            var hours = Math.floor(abs);
                            d.time_remaining_txt = hours + 'h, ' + Math.floor(mins) + 'm';
                        }
                    }

                    if(d.time_remaining < 0) {
                        d.time_remaining_status = 'overdue';
                        d.time_remaining_status_color = 'error';
                    }
                    else {
                        d.time_remaining_status = 'left';
                        if(d.time_remaining < 3) {
                            d.time_remaining_status_color = 'warning';
                        }
                        else {
                            d.time_remaining_status_color = 'success';
                        }
                    }

                    return d;
                });
            },
            accept(job) {
                this.button_type = 'action';
                this.job = job;
                if(this.$store.getters.getUser.account_type == 'admin') {
                    this.$store.dispatch('loading', true);
                    axios({
                        url : process.env.VUE_APP_API_URL + '/staffs',
                        method : 'GET',
                        headers : {
                            'Accept' : 'application/json',
                            'Authorization' : 'Bearer ' + this.$store.getters.getToken
                        }
                    }).then(response => {
                        this.show_staffs = true;
                        this.staffs = response.data;
                        this.$store.dispatch('loading', false);
                    }).catch(err => {
                        this.$store.dispatch('loading', false);
                    });
                }
                else {
                    this.$store.dispatch('loading', true);
                    axios({
                        url : process.env.VUE_APP_API_URL + '/jobs/' + job.id + '/accept',
                        method : 'PUT',
                        headers : {
                            'Authorization' : 'Bearer ' + this.$store.getters.getToken
                        }
                    }).then(response => {
                        this.$store.dispatch('loading', false);
                        this.reload();
                    }).catch(err => {
                        this.$store.dispatch('loading', false);
                    })
                }
            },
            viewJobDetails(job) {
                this.button_type = 'info';
                this.$store.dispatch('loading', true);
                this.job = job;
                axios({
                    url : process.env.VUE_APP_API_URL + '/jobs/' + job.id + '/timeline',
                    method : 'GET',
                    headers : {
                        'Accept' : 'application/json',
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(response => {
                    this.job_timelines = response.data;
                    this.show_details = true;

                    this.$store.dispatch('loading', false);
                }).catch(err => {
                    this.$store.dispatch('loading', false);
                });
            },
            showNotes(job) {
                this.job = job;
                this.show_notes = true;
            },
            goToDetails(job) {
                if(['submitted for checking', 'checking started'].indexOf(job.job_status) >= 0 && job.validator_id == this.$store.getters.getUser.id) {
                    this.$router.push({name : 'JobDetailsCheck', params : {type : this.category_id, id : job.id}})
                }
                else {
                    if(this.category_id == 1) {
                        this.$router.push({name : 'JobDetails', params : {type : this.category_id, id : job.id}})
                    }
                    else if(this.category_id > 1) {
                        this.$router.push({name : 'JobDetailsBPH', params : {type : this.category_id, id : job.id}})
                    }
                    
                }
                
            },
            getStatusList(item) {
                this.job = item;
                this.show_status = true;
            },
            updateStatus(status) {
                if(status == 'completed') {
                    if(!confirm('Are you sure?')) {
                        return;
                    }
                }

                if(status == 'LOG') {
                    axios({
                        url : process.env.VUE_APP_API_URL + '/jobs/bph/check-log-status',
                        method : 'GET',
                        params : {id : this.job.id},
                        data : {status : status},
                        headers : {
                            'Accept' : 'application/json',
                            'Authorization' : 'Bearer ' + this.$store.getters.getToken
                        }
                    }).then(res => {
                        if(res.data.id == undefined) {
                            axios({
                                url : process.env.VUE_APP_API_URL + '/jobs/'+ this.job.id +'/status',
                                method : 'PUT',
                                data : {job_status : status},
                                headers : {
                                    'Accept' : 'application/json',
                                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                                }
                            }).then(res => {
                                this.reload(this.category_id);
                                this.show_status = false;
                            }).catch(err => {
                                alert(JSON.stringify(err));
                            })
                        }
                        else {
                            alert('Log Status has already been assigned to another job.');
                        }
                    });
                }
                else {
                    axios({
                        url : process.env.VUE_APP_API_URL + '/jobs/'+ this.job.id +'/status',
                        method : 'PUT',
                        data : {job_status : status},
                        headers : {
                            'Accept' : 'application/json',
                            'Authorization' : 'Bearer ' + this.$store.getters.getToken
                        }
                    }).then(res => {
                        this.reload(this.category_id);
                        this.show_status = false;

                        if(status == 'completed') {
                            if(confirm('Job has been completed.\nDo you want to notify the client?')) {
                                this.sendClientNotification(this.job.id).then(res => {
                                    alert('Email notification has been sent');
                                }).catch(err => {
                                    alert(JSON.stringify(err));
                                })
                            }
                        }
                    }).catch(err => {
                        alert(JSON.stringify(err));
                    })
                }
            }
        }
    }
</script>