<template>
    <div class="pa-5">
        <v-card>
            <v-card-text class="grey lighten-3">
                <strong>NOTIFICATIONS</strong>
            </v-card-text>
            <v-divider></v-divider>
            <v-list dense>
                <v-list-item v-for="n in notifications" :key="n.id" link @click="viewNotification(n)">
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-badge
                                color="pink"
                                dot
                                offset-x="-10"
                                offset-y="10"
                                :value="n.has_read == 0"
                                >
                                {{ n.details }} by {{ n.sender?.name }} ({{ n.sender?.email }})
                            </v-badge>
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ n.timestamp }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </div>
</template>
<script>
import axios from 'axios';
import { mapState } from 'vuex'; // Import mapState
export default {
    name : 'Notification',
    computed: {
        ...mapState({
            notifications: state => state.notifications // Map notifications state
        })
    },
    methods: {
        viewNotification(notification) {
            notification.has_read = 1;
            this.$router.push({
                path: notification.link,
                query: {
                    read: '1',
                    nid : notification.id
                }
            });
        }
    }
}
</script>