import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/misc/LoginView.vue'
import JobsView from '@/views/jobs/JobsView.vue'
import JobDetails from '@/views/jobs/JobDetails.vue'
import JobDetailsBPH from '@/views/jobs/JobDetailsBPH.vue'
import JobDetailsCheck from '@/views/jobs/JobDetailsCheck.vue'
import UserList from '@/views/users/UserList.vue'
import NewUser from '@/views/users/NewUser.vue'
import JobTimeline from '@/views/jobs/JobTimeline.vue'
import DashboardLayoutView from '@/views/layouts/DashboardLayoutView.vue'
import BusinessTime from '@/views/business-times/BusinessTime.vue'
import Holidays from '@/views/holidays/HolidayList.vue'
import LeavesView from '@/views/leaves/LeavesView.vue'
import DashboardView from '@/views/dashboard/DashboardView.vue'
import NotificationsView from '@/views/notifications/NotificationsView.vue'
import CompaniesView from '@/views/companies/CompaniesView.vue'
import TimeSheetView from '@/views/timesheet/TimeSheetView.vue'
import StaffTimeSheetView from '@/views/timesheet/StaffTimeSheetView.vue'
import StaffLeavesView from '@/views/leaves/StaffLeavesView.vue'
import ProfileView from '@/views/user/ProfileView.vue'
import NewJobLBS from '@/views/jobs/NewJobLBS.vue'
import NewJobBPH from '@/views/jobs/NewJobBPH.vue'
import NewJobB1 from '@/views/jobs/NewJobB1.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardLayoutView,
    children : [
      {
        path : 'dashboard',
        name : 'Dashboard',
        component : DashboardView
      },
      {
        path : 'profile',
        name : 'Profile',
        component : ProfileView
      },
      {
        path : '/jobs/:type',
        name : 'Jobs',
        component : JobsView
      },
      {
        path : 'jobs/:type/details/:id',
        name : 'JobDetails',
        component : JobDetails
      },
      {
        path : 'jobs/:type/bph-details/:id',
        name : 'JobDetailsBPH',
        component : JobDetailsBPH
      },
      {
        path : 'jobs/:id/check',
        name : 'JobDetailsCheck',
        component : JobDetailsCheck
      },
      {
        path : 'jobs/lbs/add',
        name : 'NewJobLBS',
        component : NewJobLBS
      },
      {
        path : 'jobs/bph/add',
        name : 'NewJobBPH',
        component : NewJobBPH
      },
      {
        path : 'jobs/b1/add',
        name : 'NewJobB1',
        component : NewJobB1
      },
      {
        path : 'jobs/timeline/:id',
        name : 'JobTimeline',
        component : JobTimeline
      },
      {
        path : 'timesheet',
        name : 'TimeSheet',
        component : TimeSheetView
      },
      {
        path : 'staff/timesheet',
        name : 'StaffTimeSheet',
        component : StaffTimeSheetView
      },
      {
        path : 'business-times',
        name : 'BusinessTime',
        component : BusinessTime
      },
      {
        path : 'holidays',
        name : 'Holidays',
        component : Holidays
      },
      {
        path : 'leaves',
        name : 'Leaves',
        component : LeavesView
      },
      {
        path : 'staff-leaves',
        name : 'StaffLeaves',
        component : StaffLeavesView
      },
      {
        path : 'notifications',
        name : 'Notifications',
        component : NotificationsView
      },
      {
        path : 'companies',
        name : 'Companies',
        component : CompaniesView
      },
      {
        path : 'users',
        name : 'UserList',
        component : UserList
      },
      {
        path : 'users/add',
        name : 'NewUser',
        component : NewUser
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
