<template>
    <div>
        <v-navigation-drawer
            v-model="drawer"
            app
            src="@/assets/sidebar.png"
            dark
        >
            <div class="logo-text white--text text-center mt-3">
                <h3>LUNTIAN</h3>
                <small>DESIGN & SOLUTIONS</small>
            </div>
            <div class="text-center mb-5 mt-5">
                <img src="@/assets/logo.png" alt="" height="70" />
            </div>
            <v-divider></v-divider>
            <v-list dense nav dark>
                <v-list-item-group v-model="page">
                    <v-subheader>Job Management</v-subheader>
                    
                    <v-list-group
                        no-action
                        v-if="$store.getters.getUser.account_type != 'accounting'"
                        :value="false"
                         class="sub-menu"
                    >
                        <template v-slot:activator>
                            <v-list-item-icon  class="white--text">
                                <v-icon>mdi-lightning-bolt-circle</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="white--text">Energy</v-list-item-title>
                        </template>

                        <v-list-item
                            link
                            :to="{name : 'Jobs', params : {'type' : 1}}"
                            >
                            <v-list-item-content>
                                <v-list-item-title class="white--text">LBS</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            link
                            :to="{name : 'Jobs', params : {'type' : 2}}"
                            >
                            <v-list-item-content>
                                <v-list-item-title  class="white--text">BPH</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            link
                            :to="{name : 'Jobs', params : {'type' : 3}}"
                            >
                            <v-list-item-content>
                                <v-list-item-title  class="white--text">B1</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-group
                        v-if="$store.getters.getUser.account_type != 'accounting'"
                        no-action
                        :value="false"
                         class="sub-menu"
                    >
                        <template v-slot:activator>
                            <v-list-item-icon  class="white--text">
                                <v-icon>mdi-draw</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="white--text">Drafting</v-list-item-title>
                        </template>

                        <!-- <v-list-item
                            link
                            :to="{name : 'Jobs', params : {'type' : 1}}"
                            >
                            <v-list-item-content>
                                <v-list-item-title class="white--text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->
                    </v-list-group>
                    <v-divider></v-divider>
                    <v-list-item
                        v-for="(m, i) in menus"
                        :key="i"
                        link
                        :to="{name : m.link}"
                        >
                        <v-list-item-icon>
                            <v-icon>{{ m.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ m.text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    
                    <v-list-item
                        link
                        :to="{name : 'Notifications'}"
                        v-if="$store.getters.getUser.account_type != 'accounting'"
                        >
                        <v-list-item-icon>
                            <v-badge
                                bordered
                                color="red"
                                offset-x="10"
                                offset-y="10"
                                :value="notifications?.length > 0"
                                :content="notifications?.length"
                            >
                                <v-icon>mdi-bell</v-icon>
                            </v-badge>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Notifications</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        link
                        @click="logout()"
                        >
                        <v-list-item-icon>
                            <v-icon>mdi-power</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    
        <v-app-bar app color="primary" dark>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    
            <v-toolbar-title>Application</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-chip color="primary" class="lighten-2 pointer" @click="viewProfile()">
                <span class="white--text">{{ $store.getters.getUser.name }}</span>
            </v-chip>
        </v-app-bar>
    
        <v-main>
            <router-view></router-view>
        </v-main>
    </div>
</template>
<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>
<script>
import axios from 'axios';
import { mapState } from 'vuex'; // Import mapState
import { globalMixins } from '@/mixins/globalMixins';
export default {
    mixins : [globalMixins],
    computed: {
        ...mapState({
            notifications: state => state.notifications.filter(n => {
                return n.has_read === 0;
            }) // Map notifications state
        })
    },
    data() {
        return {
            drawer: null,
            page : 0,
            menus : [],
        }
    },
    mounted() {
        this.page = 1;
        var user = JSON.parse(this.$store.state.user);
        this.menus = this.getMenus(user.account_type);
        this.$store.dispatch('fetchNotifications');
    },
    methods: {
        redirect(link) {
            this.$router.push({name : link});
        },
        viewProfile() {
            this.$router.push({
                name : 'Profile'
            });
        },
        logout() {
            axios({
                url : this.$store.state.api_url + '/logout',
                method : 'POST',
                headers : {
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                this.$router.push({name : 'Login'});
            }).catch(err => {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                this.$router.push({name : 'Login'});
            });
        }
    }
}
</script>

<style scoped>
    .v-list-item--active .v-list-item__title .v-list-item__icon{
        background-color: transparent; /* Or any color you prefer */
        color: #fff !important; /* Or specify your preferred color */
    }
    .v-list .v-list-item--active .v-icon{
        color: #fff !important;
    }
</style>