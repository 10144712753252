<template>
    <div class="pa-5">
        <div class="panel mt-5">
            <v-row no-gutters>
                <v-col cols="12" sm="3" md="3" class="border-right">
                    <v-simple-table class="grey lighten-5 no-radius" dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left pa-2">
                                        <v-row>
                                            <v-col cols="12" md="7" sm="7">
                                                <v-select hide-details dense outlined
                                                    :items="months"
                                                    item-value="value"
                                                    item-text="text"
                                                    v-model="month"
                                                >
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" md="5" sm="5">
                                                <v-select hide-details dense outlined
                                                    :items="years"
                                                    item-value="value"
                                                    item-text="text"
                                                    v-model="year"
                                                >
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="d in days" 
                                    :key="d.value"
                                    @click="date = d.date;"
                                    class="pointer"
                                    :class="getClass(d.hours)">
                                    <td :class="date == d.date ? 'bold' : ''">
                                        {{ d.display_date }} 
                                        <v-chip
                                            small
                                            class="ma-2"
                                            color="green"
                                            text-color="white"
                                            v-if="d.on_leave"
                                            >
                                            Leave
                                        </v-chip>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
                <v-col cols="12" sm="8" md="9">
                    <v-simple-table dense class="no-radius">
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Details
                                </th>
                                <th class="text-left">
                                    Start
                                </th>
                                <th class="text-left">
                                    End
                                </th>
                                <th class="text-left">
                                    Hours
                                </th>
                                <th class="text-left" style="width: 150px;">
                                    Options
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="item in tasks"
                            :key="item.id"
                            >
                                <td>{{ item.task_details }}</td>
                                <td>{{ item.start_time_display }}</td>
                                <td>{{ item.end_time_display }}</td>
                                <td>{{ item.total_hours }}</td>
                                <td>
                                    <v-btn x-small color="warning" @click="editTask(item)">edit</v-btn>&nbsp;
                                    <v-btn x-small color="error" @click="deleteTask(item.id)">delete</v-btn>
                                </td>
                            </tr>
                            <tr v-if="tasks.length == 0">
                                <td colspan="5">No records found.</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="3"></td>
                                <td colspan="2" class="pa-2">
                                    <table>
                                        <tr>
                                            <td class="text-right">
                                                <strong>Name : </strong> &nbsp;
                                            </td>
                                            <td>
                                                <strong>{{ user.name }}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-right">
                                                <strong>Date : </strong> &nbsp;
                                            </td>
                                            <td>
                                                <strong>{{ date }}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-right">
                                                <strong>Total : </strong> &nbsp;
                                            </td>
                                            <td>
                                                <strong>{{ total_hours }} Hour(s)</strong>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </tfoot>
                        </template>
                    </v-simple-table>
                    <v-divider></v-divider>
                    <div class="mt-2 mb-2 pl-5 pr-5 text-right">
                        <v-btn small block color="default" @click="addTask()">Add Task Record</v-btn>
                    </div>
                    <v-divider></v-divider>
                </v-col>
            </v-row>
        </div>
        <v-dialog width="400" v-model="show_task_details">
            <v-card>
                <v-card-title>
                    <strong>Task Details - {{ date }}</strong>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-5">
                    <v-textarea outlined dense label="Job/Task Details" v-model="task.task_details"></v-textarea>
                    <div>
                        <v-label>Start Time</v-label>
                        <v-row class="mt-1">
                            <v-col>
                                <v-select 
                                    label="Hour" dense outlined
                                    :items="htime"
                                    item-text="text"
                                    item-value="value"
                                    v-model="task.start_hour">
                                </v-select>
                            </v-col>
                            <v-col>
                                <v-select 
                                    label="Minute" dense outlined
                                    :items="mtime"
                                    v-model="task.start_min"></v-select>
                            </v-col>
                            <v-col>
                                <v-text-field 
                                    label="Seconds" readonly dense outlined
                                    value="00"
                                    ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                    <div>
                        <v-label>End Time</v-label>
                        <v-row class="mt-1">
                            <v-col>
                                <v-select 
                                    label="Hour" dense outlined
                                    :items="htime"
                                    item-text="text"
                                    item-value="value"
                                    v-model="task.end_hour">
                                </v-select>
                            </v-col>
                            <v-col>
                                <v-select 
                                    label="Minute" dense outlined
                                    :items="mtime"
                                    v-model="task.end_min"></v-select>
                            </v-col>
                            <v-col>
                                <v-text-field 
                                    label="Seconds" readonly dense outlined
                                    value="00"
                                    ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="primary" small block @click="saveTaskDetails()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
export default {
    name : 'StaffTimeSheet',
    data() {
        return {
            date : null,
            menu : null,
            show_task_details : false,
            htime : [],
            mtime : [],
            tasks : [],
            task : {},
            total_hours : 0,
            days : [],
            months : [],
            month : {},
            years : [],
            year : null
        }
    },
    mounted() {
        this.user = JSON.parse(this.$store.state.user);
        this.date = this.formatDate(new Date());

        for(let i = 0; i <= 23; i++) {
            this.htime.push({
                value : i.toString().padStart(2, '0'),
                text : i.toString().padStart(2, '0')
            });
        }

        for(let i = 0; i <= 60; i += 5) {
            this.mtime.push({
                value : i.toString().padStart(2, '0'),
                text : i.toString().padStart(2, '0')
            });
        }

        this.refreshTimeSheet();

        this.year = new Date().getFullYear();
        this.month = new Date().getMonth() + 1;

        for(let i = 1; i <= 12; i++) {
            let first_day = this.year + '-' + i.toString().padStart(2, '0') + '-01';

            this.months.push({
                value : i,
                text : moment(first_day).format('MMMM')
            });
        }

        let current_year = new Date().getFullYear();
        for(let i = current_year; i >= current_year - 10; i--) {
            this.years.push({
                value : i,
                text : i
            });
        }
        
    },
    watch : {
        date(newVal) {
            this.refreshTimeSheet();
        },
        month(newVal, oldVal) {
            this.month = newVal;
            this.refreshSchedule();
        },
        year(newVal, oldVal) {
            this.year = newVal;
            this.refreshSchedule();
        }
    },
    methods : {
        refreshSchedule() {
            let date = this.year + '-' + this.month.toString().padStart(2, '0') + '-01';

            let start = moment(date).startOf('month').format('YYYY-MM-DD');
            let end = moment(date).endOf('month').format('YYYY-MM-DD');

            axios({
                url : process.env.VUE_APP_API_URL + '/staff/'+ this.user.id +'/timesheet-summary',
                params : {
                    start_time : start,
                    end_time : end
                },
                method : 'GET',
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                let output = res.data;
                this.days = output.data.map(d => {
                    d.display_date = moment(d.date).format('DD-MMM-YYYY - dddd');
                    return d;
                })
            })
        },
        refreshTimeSheet() {
            this.$store.state.loading = true;
            axios({
                url : process.env.VUE_APP_API_URL + '/staff/'+ this.user.id +'/timesheet',
                method : 'GET',
                params : {date : this.date},
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                let output = res.data;
                this.tasks = output.data.tasks.map(d => {
                    let start = new Date(d.start_time);
                    let end = new Date(d.end_time);
                    d.start_time_display = start.toLocaleTimeString()
                    d.end_time_display = end.toLocaleTimeString()
                    return d;
                });
                this.total_hours = output.data.total_hours;
                this.$store.state.loading = false;
            }).catch(err => {
                this.$store.state.loading = false;
            });
        },
        formatDate(date) {
            let year = date.getFullYear();
            let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            let day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        addTask() {
            this.show_task_details = true;
            this.task = {
                task_details : null,
                start_hour : '08',
                start_min : '00',
                end_hour : '09',
                end_min : '00'
            }
        },
        editTask(task) {
            let details = Object.assign({}, task);
            let start = new Date(task.start_time);
            let end = new Date(task.end_time);
            this.task = {
                id : details.id,
                task_details : details.task_details,
                start_hour : start.getHours().toString().padStart(2, '0'),
                start_min : start.getMinutes().toString().padStart(2, '0'),
                end_hour : end.getHours().toString().padStart(2, '0'),
                end_min : end.getMinutes().toString().padStart(2, '0')
            }

            this.show_task_details = true;
        },
        saveTaskDetails() {
            this.$store.state.loading = true;

            let task = {
                id : this.task.id,
                task_details : this.task.task_details,
                start_time : this.task.start_hour + ':' + this.task.start_min + ':00',
                end_time : this.task.end_hour + ':' + this.task.end_min + ':00',
                date : this.date
            };
           if(task.id == undefined) {
                axios({
                    url : process.env.VUE_APP_API_URL + '/staff/'+ this.user.id +'/timesheet',
                    method : 'POST',
                    data : task,
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.$store.state.loading = false;
                    this.refreshTimeSheet();
                    this.refreshSchedule();

                    this.show_task_details = false;
                }).catch(err => {
                    this.$store.state.loading = false;

                    if(err.response.status == 400) {
                        let data = err.response.data;
                        let message = data.message.toUpperCase();
                        message += "\n";
                        message += 'Task: ' + data.data.task_details;
                        message += "\n";
                        message += 'Start: ' + data.data.start_time;
                        message += "\n";
                        message += 'End: ' + data.data.end_time;
                        alert(message);
                    }
                });
            }
            else {
                axios({
                    url : process.env.VUE_APP_API_URL + '/staff/'+ this.user.id +'/timesheet/' + task.id,
                    method : 'PUT',
                    data : task,
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.$store.state.loading = false;
                    this.refreshTimeSheet();
                    this.refreshSchedule();

                    this.show_task_details = false;
                }).catch(err => {
                    this.$store.state.loading = false;

                    if(err.response.status == 400) {
                        let data = err.response.data;
                        let message = data.message.toUpperCase();
                        message += "\n";
                        message += 'Task: ' + data.data.task_details;
                        message += "\n";
                        message += 'Start: ' + data.data.start_time;
                        message += "\n";
                        message += 'End: ' + data.data.end_time;
                        alert(message);
                    }
                });
            }

            
        },
        deleteTask(id) {
            if(confirm('Are you sure?')) {
                axios({
                    url : process.env.VUE_APP_API_URL + '/staff/'+ this.user.id +'/timesheet/' + id,
                    method : 'DELETE',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.$store.state.loading = false;
                    this.refreshTimeSheet();
                    this.refreshSchedule();
                }).catch(err => {
                    this.$store.state.loading = false;
                    alert("Something went wrong. \nPlease Try again.")
                });
            }
        },
        getClass(hours) {
            if(hours > 0 && hours < 9) {
                return 'orange pointer lighten-3';
            }
            else if(hours == 9) {
                return 'green pointer lighten-3';
            }
            else if(hours > 9) {
                return 'red pointer lighten-3';
            }
        },
    }
}
</script>
<style scoped>
    .right-border {
        border-right : 1px solid rgba(0, 0, 0, 0.12);
    }
    .panel {
        border : 1px solid rgba(0, 0, 0, 0.12);
    }
    .border-right {
        border-right : 1px solid rgba(0, 0, 0, 0.12);
    }
    .border-bottom {
        border-bottom : 1px solid rgba(0, 0, 0, 0.12);
    }
    .no-radius {
        border-radius: 0px;
    }
    .pointer {
        cursor: pointer;
    }
    .bold {
        font-weight: bold;
    }
</style>