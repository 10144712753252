import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,[_c(VCardText,{staticClass:"grey lighten-2"},[_c('strong',[_vm._v("USER DETAILS")])]),_c(VCardText,[(_vm.errors.length > 0)?_c(VAlert,{attrs:{"type":"error"}},_vm._l((_vm.errors),function(e){return _c('div',{key:e},[_vm._v(_vm._s(e))])}),0):_vm._e(),_c('div',{staticClass:"mb-4"},[_c(VTextField,{attrs:{"outlined":"","label":"Name","dense":"","hide-details":""},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('div',{staticClass:"mb-4"},[_c(VTextField,{attrs:{"outlined":"","label":"Email","type":"email","dense":"","hide-details":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('div',{staticClass:"mb-4"},[_c(VSelect,{attrs:{"outlined":"","dense":"","hide-details":"","items":['admin', 'supervisor', 'staff', 'accounting'],"label":"Account Type"},model:{value:(_vm.user.account_type),callback:function ($$v) {_vm.$set(_vm.user, "account_type", $$v)},expression:"user.account_type"}})],1)],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"small":"","color":"default"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" cancel ")]),_c(VSpacer),_c(VBtn,{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.saveUser()}}},[_vm._v(" save ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }